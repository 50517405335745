<template>

<v-container class="page_container">
  
<v-img class="hero_img" :src="hero_img"></v-img>
<h2 class="page_title">{{title}}</h2>
<div class="content" v-html="content"></div>
<router-link to="/contact" class="content_cta" v-if="cta">Get in touch</router-link>
<slot></slot>
</v-container>

</template>

<script>
export default {

    name: "pageContent",

    props:[
    'hero_img',
    'title',
    'content',
    'cta'
],
components:{
}

};
</script>
<style>
.hero_img{
    height: 400px;
}
.content_cta{
    position: relative;
    top: 50px;
    padding: 10px 20px;
    border: 1px solid black;
    text-decoration: none;
    color: black;
    transition-duration: .2s;
    transition-timing-function: ease-in-out;
}
.content_cta:hover{
    background-color: black;
    color: white;
    transition-duration: .2s;
}
@media screen and (max-width: 960px){
    .hero_img{
        height: 250px;
    }
}
</style>
